import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrShinLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Shin"
    institution="Hackensack Medical Center"
    referralCode="HUMC"
    physicianURL="https://doctors.hackensackmeridianhealth.org/provider/David+Shin/1319909"
  />
)

export default DrShinLandingPage
